// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* { box-sizing: border-box; }
:root { background-color: #f7f9fb80; }
body, ul, li, p, h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0; }
body { min-height: 100vh; color: #000000; font-weight: 400; }
.overlay { display: none; }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,IAAI,sBAAsB,EAAE;AAC5B,QAAQ,2BAA2B,EAAE;AACrC,0CAA0C,SAAS,EAAE,UAAU,EAAE;AACjE,OAAO,iBAAiB,EAAE,cAAc,EAAE,gBAAgB,EAAE;AAC5D,WAAW,aAAa,EAAE","sourcesContent":["* { box-sizing: border-box; }\n:root { background-color: #f7f9fb80; }\nbody, ul, li, p, h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0; }\nbody { min-height: 100vh; color: #000000; font-weight: 400; }\n.overlay { display: none; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
